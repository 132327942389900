var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"gant-row"},[(_vm.planLength > 0)?_c('div',{staticClass:"gant-row-plan",style:({
      width: `${_vm.planLength * _vm.dayWidth}px`,
      left: `${_vm.planStartDateDay * _vm.dayWidth}px`,
    })}):_vm._e(),(_vm.realityLength > 0)?_c('div',{staticClass:"gant-row-reality",style:({
      width: `${_vm.realityLength * _vm.dayWidth}px`,
      left: `${_vm.actualStartDateDay * _vm.dayWidth}px`,
    })}):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }