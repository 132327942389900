<template>
  <div class="gant-row">
    <div
      class="gant-row-plan"
      v-if="planLength > 0"
      :style="{
        width: `${planLength * dayWidth}px`,
        left: `${planStartDateDay * dayWidth}px`,
      }"
    ></div>
    <div
      class="gant-row-reality"
      v-if="realityLength > 0"
      :style="{
        width: `${realityLength * dayWidth}px`,
        left: `${actualStartDateDay * dayWidth}px`,
      }"
    ></div>
  </div>
</template>

<script>
import moment from "moment"
export default {
  data() {
    return {
      //一天总毫秒数
      dayMill: 24 * 60 * 60 * 1000,
    }
  },
  props: {
    //当前行信息
    row: {
      type: Object,
      default: () => {
        return {}
      },
    },
    //这里接收header那边整理完成的表头数据。
    days: {
      type: Array,
      default: () => [],
    },
    //一天所占的宽度，用于定位
    dayWidth: {
      type: Number | String,
      default: 10,
    },
  },
  computed: {
    //实际开始日期
    actualStartDate() {
      return this.row.actualStartDate
      // return `${this.row.actualStartDate} 00:00:00`
    },
    //实际结束日期
    actualEndDate() {
      return this.row.actualEndDate
      // return `${this.row.actualEndDate} 00:00:00`
    },
    // 计划开始日期
    planStartDate() {
      return this.row.planStartDate
      // return `${this.row.planStartDate} 00:00:00`
    },
    //计划完成日期
    planEndDate() {
      return this.row.planEndDate
      // return `${this.row.planEndDate} 00:00:00`
    },
    //实际时长(天数*一天的宽度=条形宽度)
    realityLength() {
      if (!this.actualStartDate || !this.actualStartDate.length) return 0
      let endDate = this.actualEndDate
      if (!endDate || !endDate.length) {
        endDate = moment(new Date()).format("YYYY-MM-DD")
      }
      const start = new Date(this.actualStartDate).getTime()
      const end = new Date(endDate).getTime()
      const number = Math.round(Math.abs((end - start) / this.dayMill) + 1)
      return number
    },
    //计划时长(天数*一天的宽度=条形宽度)
    planLength() {
      if (!this.planStartDate || !this.planStartDate.length) return 0
      let endDate = this.planEndDate
      if (!endDate || !endDate.length) {
        endDate = moment(new Date()).format("YYYY-MM-DD")
      }
      const start = new Date(this.planStartDate).getTime()
      const end = new Date(endDate).getTime()
      const number = Math.round(Math.abs((end - start) / this.dayMill) + 1)
      return number
    },
    //计划开始的天数（条形左边开始位置，天数*一天的长度）
    planStartDateDay() {
      //获取计划开始的时间戳
      const start = new Date(this.planStartDate).getTime()
      let length = 0 // 记录开始距离左边的天数
      this.days.forEach((month) => {
        month.days.forEach((dayRand) => {
          dayRand.des.forEach((dayTime) => {
            //对比两个时间戳的大小，如果小如开始时间，则当天计划还没开始。
            if (dayTime < start) {
              length++
            }
          })
        })
      })
      return length
    },
    //实际开始的天数（条形左边开始位置，天数*一天的长度）
    actualStartDateDay() {
      const start = new Date(this.actualStartDate).getTime()
      let length = 0 // 记录开始距离左边的天数
      this.days.forEach((month) => {
        month.days.forEach((dayRand) => {
          dayRand.des.forEach((dayTime) => {
            if (dayTime < start) {
              length++
            }
          })
        })
      })
      return length
    },
  },
}
</script>

<style lang="less" scoped>
.gant-row {
  width: 100%;
  height: 40px;
  border-bottom: rgba(255, 255, 255, 0.1) 1px solid;
  min-height: 40px;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  &-plan {
    position: absolute;
    top: 11px;
    z-index: 1;
    height: 20px;
    background: #007ff4;
    flex-shrink: 0;
    font-size: 10px;
    &-hide {
      background: transparent;
    }
  }
  &-reality {
    position: absolute;
    top: 11px;
    z-index: 2;
    height: 10px;
    background: #ffb822;
  }
}
</style>
