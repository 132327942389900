<template>
  <div class="gant">
    <div
      class="gant-header"
      ref="header"
    >
      <gant-header
        @initEnd="initEnd"
        :min="sort.min"
        :max="sort.max"
        :dayWidth="dayWidth"
      ></gant-header>
    </div>
    <div
      class="gant-rows"
      ref="gant"
      @scroll="gantScroll"
    >
      <gant-row
        :style="{ width: `${clientWidth}px` }"
        v-for="(row, index) in gantRows"
        :key="`gant_row_${row.id}_${index}`"
        :row="row"
        :days="daysInfo.list"
        :dayWidth="dayWidth"
      ></gant-row>
    </div>
    <div class="gant-line" :style="{left: `${lineLeft * dayWidth}px`}"></div>
  </div>
</template>

<script>
import GantHeader from "./gant-header.vue"
import GantRow from "./gant-row.vue"
import moment from 'moment'
export default {
  components: { GantHeader, GantRow },
  data() {
    return {
      daysInfo: [],
      row_scrollTop: 0,
      clientWidth: 0,
      gantRows:[],
      sort: {
        min: 0,
        max: 0,
      },
    }
  },
  props: {
    //数据列表
    list: {
      type: Array,
      default: () => [],
    },
    //垂直滚动位置
    scrollY: {
      type: Number,
      default: 0,
    },
    //一天所占的宽度，用于定位
    dayWidth: {
      type: Number | String,
      default: 10,
    },
  },
  watch: {
    scrollY: {
      immediate: true,
      handler(val) {
        this.row_scrollTop = val
        this.$nextTick(() => {
          this.$refs.gant.scrollTop = val
        })
      },
    },
    list: {
      deep: true,
      immediate: true,
      handler(val) {
        this.gantRows = []
        this.recursive(val)
        this.sortDays(this.gantRows)
      }
    }
  },
  computed:{
    lineLeft() {
      //获取计划开始的时间戳
      const start = new Date().getTime()
      let length = 0 // 记录开始距离左边的天数
      this.daysInfo.list?.forEach((month) => {
        month.days.forEach((dayRand) => {
          dayRand.des.forEach((dayTime) => {
            //对比两个时间戳的大小，如果小如开始时间，则当天计划还没开始。
            if (dayTime < start) {
              length++
            }
          })
        })
      })
      return length
    },
  },
  mounted(){
    Array.from(document.querySelectorAll('.gantSelect')).map(p => {
      p.scrollIntoView()
    })
  },
  methods: {
    // 递归
    recursive(list){
      for (let i = 0; i < list.length; i++) {
        const row = list[i]
        const cpy_row = JSON.parse(JSON.stringify(row))
        this.gantRows.push(cpy_row)
        //如果没有子节点，没必要继续走下去了
        if (!cpy_row.children) continue
        //如果是展开状态，保存子数据
        if (cpy_row.expand) {
          this.recursive(cpy_row.children)
        }
      }
    },
    //对列表数据进行整理，找出最大、最小日期
    sortDays(gantRows) {
      let list = []
      //找出最大、最小的值
      gantRows.forEach((row) => {
        // 实际开始时间
        if(row.actualStartDate){
          const actualStartDate = new Date(row.actualStartDate).getTime()
          list.push({
            time: actualStartDate,
            str: row.actualStartDate,
          })
        }
        // 实际结束时间
        if(row.actualEndDate){
          const actualEndDate = new Date(row.actualEndDate).getTime()
          list.push({
            time: actualEndDate,
            str: row.actualEndDate,
          })
        }
        //计划开始时间
        const planStartDate = new Date(row.planStartDate).getTime()
        //计划结束时间
        const planEndDate = new Date(row.planEndDate).getTime()
        list.push({
          time: planStartDate,
          str: row.planStartDate,
        })
        list.push({
          time: planEndDate,
          str: row.planEndDate,
        })
        if (row.children) {
          this.sortDays(row.children, list)
        }
      })
      // 取当天日期，如果最大日期小于今日，则取当天为最大日期
      let curDate = moment(new Date()).format('YYYY-MM-DD')
      //对整理后的数据进行排序，栈顶为最大，栈低为最小
     list = list.sort((a, b) => b.time - a.time)
      const info = {
        max: list[0].time > new Date(curDate).getTime() ? list[0].str : curDate,
        min: list[list.length - 1].str,
      }
      //保存排序结果
      this.sort = info
      console.log(this.sort,'sort');
    },
    //甘特图标题整理完数据返回的结果，主要是日期、天数的数据
    initEnd(val) {
      this.daysInfo = val
      this.$nextTick(() => {
        //获取头部的总长度，用于row的宽度统一。
        this.clientWidth = this.$refs.header.clientWidth
      })
    },
    //滚动监听
    gantScroll(event) {
      this.row_scrollTop = event.target.scrollTop
      this.$emit("update:scrollY", this.row_scrollTop)
    },
  },
}
</script>

<style lang="less" scoped>
.gant {
  flex: 1;
  position: relative;
  height: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  flex-shrink: 0;
  &-line {
    position: absolute;
    top: 60px;
    bottom: 0;
    width: 1px;
    border-left: 1px dashed #FC4A66;
  }
  &::-webkit-scrollbar{
    height: 6px;
    background-color: #aaa;
    border-radius: 3px;
    &-thumb{
      background-color: #fff;
      border-radius: 3px;
    }
  }
  &-header {
    display: flex;
    flex-direction: row;
    background: #1e5087;
    min-width: 100%;
    height: 60px;
    width: fit-content;
  }
  &-rows {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    width: fit-content;
    height: calc(100% - 60px);
    &::-webkit-scrollbar{
      width: 0px;
    }
  }
}
</style>
