<template>
  <div class="gant-header">
    <div
      v-for="month in list"
      :key="month.title"
      class="gant-header-month"
    >
      <div class="gant-header-month-title">{{ month.title }}</div>
      <div class="gant-header-month-days">
        <div
          v-for="day in month.days"
          :key="day.value + month.title"
          :class="{ gantSelect: (day.des[0] <= curDate && curDate <= day.des[day.des.length-1])}"
          :style="{ width: `${day.des.length * dayWidth}px`}"
        >
          {{ day.value }}日
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  data() {
    return {
      //整理后的日期数据
      list: [],
    }
  },
  props: {
    // 最大的日期
    max: String,
    //最小的如期
    min: String,
    //一天所占的宽度，用于定位
    dayWidth: {
      type: Number | String,
      default: 10,
    },
  },
  created() {
    this.initMonthList()
  },
  computed:{
    curDate() {
      return new Date(moment(new Date()).format('YYYY-MM-DD')+ ' 00:00:00').getTime()
    }
  },
  methods: {
    //初始化日期数据
    initMonthList() {
      // 解析出最小日期的年月日
      const minDate = this.getDateInfo(this.min)
      let minYear = minDate.year
      let minMonth = minDate.month
      let minDateStr = `${minYear}-${minMonth}`
      const list = []
      //从最小的日期开始，和最大的日期进行对比，逐月累加。得出每个月的数据。
      while (this.contrastDate(minDateStr, this.max)) {
        //分析当前月份信息，包括当月所有天的时间戳、最大天数等信息。
        const days = this.createMonthData(minYear, minMonth)
        list.push(days)

        //切换到下一月，进行下一个循环。
        minMonth += 1
        //如果超过12月，转到第二年
        if (minMonth > 12) {
          minMonth = 1
          minYear += 1
        }
        minDateStr = `${minYear}-${minMonth}`
      }
      //整理完数据，把整理结果抛出去给row那边使用。
      this.$emit("initEnd", {
        list: list,
      })
      this.list = list
    },
    //整理指定月份的数据
    createMonthData(year, month) {
      //获取当前月份最大天数，即判断当月是29天、30天、31天。
      const day = new Date(year, month, 0).getDate()
      //顶部标题使用的格式
      const days = [
        {
          value: "1-15",
          des: [],
        },
        {
          value: `16-${day}`,
          des: [],
        },
      ]
      //记录每一天的时间戳，在row里定位要用到。
      for (let i = 1; i < 16; i++) {
        const des = `${year}-${month}-${i} 00:00:00`
        days[0].des.push(new Date(des).getTime())
      }
      for (let i = 16; i <= day; i++) {
        const des = `${year}-${month}-${i} 00:00:00`
        days[1].des.push(new Date(des).getTime())
      }
      return {
        title: `${year}年${month}月`,
        days,
        maxDay: day,
      }
    },
    //获取字符串日期年/月/日
    getDateInfo(time) {
      const date = new Date(time)
      const year = date.getFullYear()
      const month = date.getMonth() + 1
      const day = date.getDate()
      return { year, month, day }
    },
    // 对比是否小于/等于当前时间
    contrastDate(min, max) {
      //获取对比两个时间字段的时间戳
      const minDate = new Date(min).getTime()
      const maxDate = new Date(max).getTime()
      return minDate <= maxDate
    },
  },
}
</script>

<style lang="less" scoped>
.gant-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #1e5087;
  &-month {
    display: flex;
    flex-direction: column;
    width: fit-content;
    color: #ffffff;
    height: 100%;
    &-title {
      font-weight: bold;
      font-size: 14px;
      color: #ffffff;
      line-height: 21px;
      height: 100%;
      flex: 1;
      text-align: center;
      border-bottom: rgba(255, 255, 255, 0.1) 1px solid;
      border-right: rgba(255, 255, 255, 0.1) 1px solid;
      justify-content: center;
      align-items: center;
      display: flex;
    }
    &-days {
      display: flex;
      flex-direction: row;
      height: 100%;
      flex: 1;
      & > div {
        font-weight: bold;
        font-size: 14px;
        height: 100%;
        color: #ffffff;
        text-align: center;
        border-right: rgba(255, 255, 255, 0.1) 1px solid;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .gantSelect{
        background: #66BBF9;
      }
    }
  }
}
</style>
